
import { FormController } from '@icepanel/app-form'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import { OrganizationModule } from '@/modules/organization/store'
import * as firebase from '@/plugins/firebase'

import * as analytics from '../helpers/analytics'
import * as authListener from '../helpers/auth-listener'
import { UserModule } from '../store'

@Component({
  name: 'UserVerifyEmail'
})
export default class extends Vue {
  organizationModule = getModule(OrganizationModule, this.$store)
  userModule = getModule(UserModule, this.$store)

  formController = new FormController({
    initialModel: {
      email: this.$route.query.email as string | undefined,
      token: this.$route.query.token as string | undefined,
      userId: this.$route.query.user as string | undefined
    }
  })

  created () {
    this.formController.submitHandler = async model => {
      await this.userModule.userLogout()

      if (!model.userId) {
        throw new Error('User was not found')
      }
      if (!model.email) {
        throw new Error('Verification email was not found')
      }
      if (!model.token) {
        throw new Error('Verification token was not found')
      }

      const res = await this.userModule.userVerifyEmail({
        token: model.token,
        userId: model.userId
      })

      analytics.userVerifyEmail.track(this)

      try {
        authListener.initialize()

        await this.userModule.userLogin({
          app: firebase.app,
          token: res.authToken
        })

        this.$router.push({
          name: 'organizations',
          query: this.$setQuery({
            email: undefined,
            token: undefined,
            userId: undefined
          })
        })
      } catch (err: any) {
        if (err.body.code === 'auth/domain-requires-saml') {
          this.$router.push({
            name: 'user-login-saml',
            query: this.$setQuery({
              email: model.email,
              login: '1'
            })
          })
        } else {
          throw err
        }
      }
    }
  }

  mounted () {
    analytics.userVerifyEmailScreen.track(this)

    this.formController.submit()
  }
}
